import React from "react"

import { client } from "./apollo/client"
import AuthProvider from "./src/hooks/useAuth"
import { ApolloProvider } from "@apollo/client"

import { Provider } from "react-redux"
import createStore from "./src/state/store"

require("./src/assets/spinnerStyle.css")
require("./src/assets/prism.css")
// import TopLayout from "./src/TopLayout"


// eslint-disable-next-line react/display-name,react/prop-types
export const wrapRootElement = ({ element }) => {
  const store = createStore()
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <AuthProvider>
          {/*<TopLayout>*/}
            {element}
          {/*</TopLayout>*/}
        </AuthProvider>
      </Provider>
    </ApolloProvider>
  )
}
