import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  // from,
} from "@apollo/client"
// import { TokenRefreshLink } from "apollo-link-token-refresh"
import { onError } from "apollo-link-error"
import fetch from "isomorphic-unfetch"
// import uuid from "uuid"

import possibleTypes from "./possibleTypes.json"
import {
  logout,
  getInMemoryAuthToken,
  // deleteRefreshToken,
  // getRefreshToken,
  // isTokenExpired,
  // setAuthToken,
} from "../src/services/auth"
import { navigate } from "gatsby"


const authMiddleware = new ApolloLink((operation, forward) => {
  // get the authentication token from local storage if it exists
  const token = getInMemoryAuthToken()

  operation.setContext(({ headers = {} }) => {
    return {
      headers: {
        ...headers,
        // authorization: getInMemoryAuthToken() || null,
        Authorization: token ? `Bearer ${token}` : "",
      },
    }

  })

  return forward(operation).map(response => {

    // Update session data.
    const context = operation.getContext()
    const { response: { headers } } = context
    // const session = headers.get("woocommerce-session")
    //
    // if (session) {
    //   if (session === "false") {
    //     localStorage.removeItem("woo-session")
    //   } else if (localStorage.getItem("woo-session") !== session) {
    //     localStorage.setItem("woo-session", headers.get("woocommerce-session"))
    //   }
    // }
    // // Update token if changed.
    // const authToken = get(response, 'data.login.authToken');
    // if (authToken && authToken !== localStorage.getItem('user-token')) {
    //   localStorage.setItem('user-token', authToken);
    // }

    return response
  })
})

// Authorization afterware
// const afterware = new ApolloLink((operation, forward) => forward(operation)
//   .map((response) => {
//     console.log("afterware", response)
//
//     // Update session data.
//     const context = operation.getContext()
//     const { response: { headers } } = context
//     const session = headers.get("woocommerce-session")
//     console.log("afterware header", headers)
//
//     if (session) {
//       if (session === "false") {
//         localStorage.removeItem("woo-session")
//       } else if (localStorage.getItem("woo-session") !== session) {
//         localStorage.setItem("woo-session", headers.get("woocommerce-session"))
//       }
//     }
//     //
//     // // Update token if changed.
//     // const authToken = get(response, 'data.login.authToken');
//     // if (authToken && authToken !== localStorage.getItem('user-token')) {
//     //   localStorage.setItem('user-token', authToken);
//     // }
//
//     return response
//   }))


// const refreshTokenLink = new TokenRefreshLink({
//   accessTokenField: `authToken`,
//   isTokenValidOrUndefined: () => {
//     const token = getInMemoryAuthToken()
//     return !token || (token && !isTokenExpired(token))
//   },
//   fetchAccessToken: () => {
//     console.log("refreshTokenLink")
//     // TODO: Check if refreshJwtAuthToken can return authExpiration
//     const query = `
//           mutation RefreshJWTAuthToken($input: RefreshJwtAuthTokenInput!) {
//             refreshJwtAuthToken(input: $input) {
//               authToken
//             }
//           }
//         `
//     return fetch(process.env.GRAPHQL_URL || "https://services.mobileui.dev/graphql", {
//       method: "POST",
//       mode: "cors",
//       credentials: "include",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         query,
//         variables: {
//           input: {
//             jwtRefreshToken: getRefreshToken(),
//             clientMutationId: uuid(),
//           },
//         },
//       }),
//     })
//   },
//   handleFetch: response => {
//     if (response.errors && response.errors.length) return
//     console.log("HandleFetch", response)
//     setAuthToken(response.authToken)
//   },
//   // handleResponse: (operation, accessTokenField) => response => {
//   //   console.log("HandleResponse:", response)
//   // },
//   handleError: err => {
//     // console.error(err)
//     deleteRefreshToken()
//   },
// })

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((data) => {
      const { message, locations, path, extensions } = data
      if (extensions && (extensions.code === "invalid-jwt" || extensions.category === "internal")) {
        logout(() => navigate("/"))
      }
      console.log(`[GraphQL error]:`, message)
    })
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})


const httpLink = new HttpLink(
  {
    uri: process.env.GRAPHQL_URL || "https://services.mobileui.dev/graphql",
    fetch,
    credentials: "include",
    fetchOptions: {
      credentials: 'include',
    },
  },
)

export const client = new ApolloClient({
  link: ApolloLink.from([
    authMiddleware,
    onErrorLink,
    // refreshTokenLink,
    // afterware,
    httpLink,
  ]),

  credentials: 'include',

  cache: new InMemoryCache({ possibleTypes }),
})
